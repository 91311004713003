import React, { useEffect, useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import useIncidentContext from '../../lib/hooks/useIncidentContext';
import ColumnInputField from 'components/templatePage1/columnInputField';

const headerFillingDetails = [
  { text: 'YSD', value: '' },
  { text: 'oralDay', value: '' },
  { text: 'oralMonth', value: '' },
  { text: 'oralYear', value: '' },
  { text: 'stateRegNo', value: '' },
  { text: 'pctSerialNo', value: '' },
  { text: 'time', value: '' },
  { text: 'pct', value: '' },
];

function HeaderFillingDetails() {
  const { generatedResult } = useIncidentContext();

  const [headerFillingsDetails, setHeaderFillingDetails] = useState(headerFillingDetails);

    useEffect(() => {
    if (generatedResult) {
      setHeaderFillingDetails([
        { text: 'YSD', value: generatedResult?.ysd == 'NA'?' ': generatedResult?.ysd },
        { text: 'oralDay', value: generatedResult?.date == 'NA'?' ': generatedResult?.date},
        { text: 'stateRegNo', value: generatedResult?.stateRegNo == 'NA'?' ': generatedResult?.stateRegNo},
        { text: 'pctSerialNo', value: generatedResult?.precinct?.serialNo == 'NA'?' ': generatedResult?.precinct?.serialNo},
        { text: 'time', value: generatedResult?.time == 'NA'?' ': generatedResult?.time},
        { text: 'pct', value: generatedResult?.precinct?.precinctName == 'NA'?' ': generatedResult?.precinct?.precinctName},
      ]);
    }
  }, [generatedResult]);

  const inputHandler = (index: number, value: any) => {
    setHeaderFillingDetails((prevList) => {
      const updatedList = [...prevList];
      updatedList[index].value = value;
      return updatedList;
    });
  };

  return (
    <>
      <Col className="h-100">
        <Row className="h-50 p-0 h-100 border border-2 border-dark">
          <Col className="border h-100 p-0 m-0 ">
            <ColumnInputField inputFieldText="YSD No." index={0} userInputHandler={inputHandler} />
          </Col>
          <Col className="h-100 p-0 border-2 border-start border-end border-dark">
            <span className="h-25">Oral Rpt. Date</span>
            <div className="d-flex flex-row p-0">
              <ColumnInputField index={2} userInputHandler={inputHandler} defaultValue={headerFillingsDetails[1].value} />{' '}
            </div>
          </Col>
          <Col className="h-100 p-0">
            <ColumnInputField inputFieldText="State Registry No." index={4} userInputHandler={inputHandler} defaultValue={headerFillingsDetails[2].value} />
          </Col>
        </Row>
        <Row className="h-50 border border-top-0 border-2 border-dark">
          <Col className="h-100 p-0">
            <ColumnInputField inputFieldText="Pct. Serial No." index={5} userInputHandler={inputHandler} defaultValue={headerFillingsDetails[3].value} />
          </Col>
          <Col className="h-100 p-0 border-2 border-start border-end border-dark">
            <ColumnInputField inputFieldText="Time" index={6} userInputHandler={inputHandler} defaultValue={headerFillingsDetails[4].value} />
          </Col>
          <Col className="h-100 p-0">
            <ColumnInputField inputFieldText=" Pct." index={7} userInputHandler={inputHandler} defaultValue={headerFillingsDetails[5].value} />
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default HeaderFillingDetails;
