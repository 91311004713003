import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Col from 'react-bootstrap/Col';
import { For } from 'tsx-control-statements/components';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import useIncidentContext from '../../lib/hooks/useIncidentContext';
import RowTypeInput from './rowTypeInput';


const getBasicOfSuspicion = (generatedResult: any): {[key: string]: string} => {
  let noChildren = 0;
  let result: {[key: string]: number[]} = {};

  generatedResult.household.forEach((item: { isChild: any; gotAbusedOrMaltreated: { [x: string]: boolean; }; }, index: number) => {
      if (item.isChild) {
          noChildren++;
          for (let key in item.gotAbusedOrMaltreated) {
              if (item.gotAbusedOrMaltreated[key]) {
                  if (!result[key]) {
                      result[key] = [];
                  }
                  result[key].push(index);
              }
          }
      }
  });

  return Object.fromEntries(Object.entries(result).map(([k, v]) => {
    if (k === 'otherDetails') {
      return [k.toLowerCase(), v];
    }
    return [k.toLowerCase(), v.length === noChildren ? 'ALL' : (v as number[]).map((index: number) => index + 1).join(',')];
  }));
}


function BasicSuspicions() {
  const { generatedResult } = useIncidentContext();
  const [other, setOther] = useState({ otherText: '', otherDetails: '' });
  const [suspicions, setSuspicions] = useState([
    [
      { text: 'DOA / Fatality', value: '' },
      { text: 'Child’s Drug/Alcohol Use', value: '' },
      { text: 'Educational Neglect', value: '' },
    ],
    [
      { text: 'Fractures', value: '' },
      { text: 'Poisoning/Noxious Substances', value: '' },
      { text: 'Emotional Neglect', value: '' },
    ],
    [
      { text: 'Internal Injuries (e.g., subdural hematoma)', value: '' },
      { text: 'Choking/Twisting/Shaking', value: '' },
      { text: 'Inadequate Food/Clothing/Shelter', value: '' },
    ],
    [
      { text: 'Lacerations/Bruises/Welts', value: '' },
      { text: 'Lack of Medical Care', value: '' },
      { text: 'Lack of Supervision', value: '' },
    ],
    [
      { text: 'Burns/Scalding', value: '' },
      { text: 'Malnutrition/Failure to Thrive', value: '' },
      { text: 'Abandonment', value: '' },
    ],
    [
      { text: 'Swelling/Dislocation/Sprains', value: '' },
      { text: 'Inadequate Guardianship', value: '' },
      { text: 'Parent’s Drug/Alcohol Misuse', value: '' },
    ],
    [
      { text: 'Excessive Corporal Punishment', value: '' },
      { text: 'Sexual Abuse', value: '' },
    ],
  ]);

  useEffect(() => {
    if (generatedResult) {
      const details: {[key: string]: string} = getBasicOfSuspicion(generatedResult)
      setSuspicions([
        [
          { text: 'DOA / Fatality', value: 'doafatality' in details ? details.doafatality : ''},
          { text: 'Child’s Drug/Alcohol Use', value: 'childdrugalcoholuse' in details ? details.childdrugalcoholuse : ''},
          { text: 'Educational Neglect', value: 'educationalneglect' in details ? details.educationalneglect : ''},
        ],
        [
          { text: 'Fractures', value: 'fractures' in details ? details.fractures : ''},
          { text: 'Poisoning/Noxious Substances', value: 'poisoningnoxioussubstances' in details ? details.poisoningnoxioussubstances : ''},
          { text: 'Emotional Neglect', value: 'emotionalneglect' in details ? details.emotionalneglect : ''},
        ],
        [
          { text: 'Internal Injuries (e.g., subdural hematoma)', value: 'internalinjuries' in details ? details.internalinjuries : ''},
          { text: 'Choking/Twisting/Shaking', value: 'chokingtwistingshaking' in details ? details.chokingtwistingshaking : ''},
          { text: 'Inadequate Food/Clothing/Shelter', value: 'inadequatefoodclothingshelter' in details ? details.inadequatefoodclothingshelter : ''},
        ],
        [
          { text: 'Lacerations/Bruises/Welts', value: 'lacerationsbruiseswelts' in details ? details.lacerationsbruiseswelts : ''},
          { text: 'Lack of Medical Care', value: 'lackofmedicalcare' in details ? details.lackofmedicalcare : ''},
          { text: 'Lack of Supervision', value: 'lackofsupervision' in details ? details.lackofsupervision : ''},
        ],
        [
          { text: 'Burns/Scalding', value: 'burnsscalding' in details ? details.burnsscalding : ''},
          { text: 'Malnutrition/Failure to Thrive', value: 'malnutritionfailuretothrive' in details ? details.malnutritionfailuretothrive : ''},
          { text: 'Abandonment', value: 'abandonment' in details ? details.abandonment : ''},
        ],
        [
          { text: 'Swelling/Dislocation/Sprains', value: 'swellingdislocationsprains' in details ? details.swellingdislocationsprains : ''},
          { text: 'Inadequate Guardianship', value: 'inadequateguardianship' in details ? details.inadequateguardianship : ''},
          { text: 'Parent’s Drug/Alcohol Misuse', value: 'parentsdrugalcoholmisuse' in details ? details.parentsdrugalcoholmisuse : ''},
        ],
        [
          { text: 'Excessive Corporal Punishment', value: 'excessivecorporalpunishment' in details ? details.excessivecorporalpunishment : ''},
          { text: 'Sexual Abuse', value: 'sexualabuse' in details ? details.sexualabuse : ''},
        ],
      ])
      setOther({ otherText: 'other' in details ? details.other : '', otherDetails: 'other' in details  && 'otherdetails' in details ? details.otherdetails : ''});
    }
  }, [generatedResult]);

  const inputHandler = (index1: number, index2: number, value: any) => {
    setSuspicions((prevList) => {
      const updatedList = [...prevList];
      updatedList[index1][index2].value = value;
      return updatedList;
    });
  };
  const otherInputHandler = (text: string, value: any) => {
    if (text == 'otherText') {
      setOther({ ...other, otherText: value });
    } else {
      setOther({ ...other, otherDetails: value });
    }
  };

  return (
    <div>
      <div className="d-flex flex-row justify-content-center border-top border-bottom border-dark bg-secondary bg-opacity-75">Basis of Suspicions</div>
      <div>
        <div>
          <p className={classNames(css(styles.paragraphFont))}>Alleged consequences or evidence of abuse or maltreatment – Give child(ren)’s line number(s). If all children, write “ALL”.</p>
        </div>
        <div className="p-1">
          <For
            each="rowitems"
            of={suspicions}
            body={(rowitems, index1) => (
              <Row>
                <Col>
                  <If condition={rowitems[0]}>
                    <RowTypeInput inputFieldText={rowitems[0].text} userInputHandler={inputHandler} index1={index1} index2={0} defaultValue={rowitems[0].value} />
                  </If>
                </Col>
                <Col>
                  <If condition={rowitems[1]}>
                    <RowTypeInput inputFieldText={rowitems[1].text} userInputHandler={inputHandler} index1={index1} index2={1} defaultValue={rowitems[1].value} />
                  </If>
                </Col>
                <Col>
                  <If condition={rowitems[2]}>
                    <RowTypeInput inputFieldText={rowitems[2]?.text} userInputHandler={inputHandler} index1={index1} index2={2} defaultValue={rowitems[2].value} />
                  </If>
                </Col>
              </Row>
            )}
          />
          <Row>
            <Col className="col-4"></Col>
            <Col className="col-8 d-flex flex-row">
              <InputGroup size="sm" className={classNames(css(styles.normalFont), 'd-flex flex-row h-100 p-0 border-0 rounded-0 w-100 p-0')}>
                <Form.Control
                  className={classNames(css(styles.inputBoxColur, styles.inputBoxWidth), 'border-0 rounded-0 border-bottom border-1 border-dark')}
                  onChange={(e) => otherInputHandler('otherText', e.target.value)}
                  value={other.otherText}
                />
                <InputGroup.Text className="text-dark w-25 border-0">Other (specify)</InputGroup.Text>
                <Form.Control
                  className={classNames(css(styles.inputBoxColur), 'border-0 rounded-0 border-bottom border-1 border-dark')}
                  onChange={(e) => otherInputHandler('otherDetails', e.target.value)}
                  value={other.otherDetails}
                />
              </InputGroup>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  inputBoxColur: {
    background: '#C7E4FF',
  },
  inputBoxWidth: {
    maxWidth: '10%',
  },
  normalFont: {
    fontSize: '0.9rem',
  },
  paragraphFont: {
    fontSize: '0.8rem',
  },
});
export default BasicSuspicions;
