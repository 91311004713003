import React, { useState, useEffect, useMemo } from "react";

import { Button, Col, Container, Form, Row } from "react-bootstrap";
import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";

import {
  faChevronRight,
  faMicrophone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AudioVisualizer from "./AudioVisualizer";
import Colors from "constants/colors";
import GeneralRoutes from "constants/generalRoutes";
import Roles from "constants/roles";
import SpeechToText from "./speechToText/SpeechToText";
import { useNavigate } from "react-router-dom";
import useIncidentContext from "../lib/hooks/useIncidentContext";

function InsertCase() {
  const navigate = useNavigate();
  const { incidentText, setIncidentText } = useIncidentContext();
  const {onStart, onStop, transcribedData, interimTranscribedData} = SpeechToText();

  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [temp, setTemp] = useState<string>("");

  const handleNextButtonClick = () => {
    setIncidentText(text);
    navigate("/" + Roles.Officer + GeneralRoutes.SelectTemplate, {
      state: { text },
    });
  };

  useMemo(() => {
    if (incidentText) setText(incidentText);
  }, [incidentText]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleClick = () => {
    setIsClicked((prevClicked) => !prevClicked);
    if (!isClicked) {
      onStart() 
      return;
    }
    onStop()
  };


  useEffect(() => {
    setText(transcribedData.join(' '))
  }, [transcribedData])

  return (
    <Container fluid className="px-4">
      <Row>
        <h1>Tell Us What happened?</h1>

        <div className="mt-3">
          <Form className="">
            <Form.Control
              as="textarea"
              placeholder="Type here..."
              value={text + interimTranscribedData}
              onChange={handleInputChange}
              className={css(styles.textArea)}
            />
          </Form>
        </div>
      </Row>
      <Row className="mt-3">
        <Col
          md={{ span: 2, offset: 5 }}
          className="d-flex flex-column align-items-center"
        >
          <Choose>
            <When condition={isClicked}>
              <AudioVisualizer isRecording={isClicked} />
              <div
                onClick={handleClick}
                className={classNames(
                  css(styles.circle),
                  "d-flex justify-content-center align-items-center bg-dark"
                )}
              >
                <FontAwesomeIcon
                  icon={faMicrophone}
                  color={Colors.Merino}
                  className={classNames(
                    css(styles.icon),
                    "justify-content-center fa-spin"
                  )}
                />
              </div>
            </When>
            <Otherwise>
              <div className={css(styles.visualizer)} />
              <div
                onClick={handleClick}
                className={classNames(
                  css(styles.circle),
                  "flex-center background-white"
                )}
              >
                <FontAwesomeIcon
                  icon={faMicrophone}
                  className={classNames(
                    css(styles.icon),
                    "justify-content-center"
                  )}
                />
              </div>
            </Otherwise>
          </Choose>
        </Col>
        <Col className="my-auto d-flex justify-content-end">
          <Button onClick={handleNextButtonClick} size="lg">
            <span>Next</span>
            <FontAwesomeIcon size="sm" icon={faChevronRight} className="ms-2" />
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  circle: {
    position: "relative",
    borderRadius: "50%",
    width: "12vh",
    height: "12vh",
    minHeight: "60px",
    minWidth: "60px",
    border: "1px solid",
    cursor: "pointer",
  },
  icon: {
    fontSize: "5vh",
    minHeight: "20px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  textArea: {
    border: "2px solid",
    height: "60vh",
    maxHeight: "60vh",
    overflowY: "auto",
  },
  visualizer: {
    height: '2.5rem'
  }
});

export default InsertCase;
