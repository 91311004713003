import React, { useState } from 'react'
import { IncidentContext } from '../hooks/useIncidentContext';

function IncidentContextProvider({ children }: any) {

    const [incidentText, setIncidentText] = useState("");
    const [templateType, setTemplateType] = useState("")
    const [generatedResult, setGeneratedResult] = useState("")

  return (
    <IncidentContext.Provider value={{ incidentText, setIncidentText, generatedResult, setGeneratedResult }}>
        {children}
    </IncidentContext.Provider>
  )
}

export default IncidentContextProvider