import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter as Router } from 'react-router-dom';

import NYPD from 'containers/NYPD';
import './styles/nypd.scss';
import { AWSCognitoProvider } from 'contexts/AWSCognitoContext';

const element = document.getElementById('root');
if (!element) {
  throw new Error('Element not found');
}
const root = ReactDOM.createRoot(element);
root.render(
  <StrictMode>
    <Router>
      <AWSCognitoProvider>
        <NYPD />
      </AWSCognitoProvider>
    </Router>
  </StrictMode>
);
