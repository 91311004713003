import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import Colors from 'constants/colors';
import React from 'react';
import { Container, Navbar, NavDropdown } from 'react-bootstrap';
import useCurrentUser from 'hooks/useCurrentUser';
import {UserProfileRoutes} from 'constants/generalRoutes';
import Roles from 'constants/roles';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuth from 'hooks/useAuth';
import {SidebarItems} from 'constants/sidebarItems';

function TopNavBar() {
  const currentUser = useCurrentUser();
  const { logout } = useAuth();
  const userProfileContent = currentUser?.profile_picture_url !== null ? (
    <img
      src={currentUser?.profile_picture_url}
      alt="user"
      className={classNames(css(styles.userProfile), 'me-2 rounded-circle')}
    />
  ) : (
    <FontAwesomeIcon
      icon={faUser}
      className={classNames(css(styles.userProfile), 'me-2 rounded-circle')}
    />
  );

  return (
    <Navbar className={css(styles.topNavbar)}>
      <Container fluid className="justify-content-between text-dark">
        <div></div>
        <div>
          <div className="d-flex">
            <NavDropdown
              id="nav-dropdown-dark-example"
              title={
                <div className="d-flex">
                  <div className="me-2 my-auto">
                    <h4 className="m-auto">{currentUser?.firstName}</h4>
                    <p className={classNames(css(styles.role), 'ml-auto mt-0 text-right')}>
                      {currentUser?.role}
                    </p>
                  </div>
                  {userProfileContent}
                </div>
              }
              menuVariant="dark"
              className="my-auto">
              <NavDropdown.Item href={SidebarItems[0].link}>Home</NavDropdown.Item>
              <NavDropdown.Item href={'/'+`${Roles.User}`+`${UserProfileRoutes.UserAccount}`}>Profile</NavDropdown.Item>
              <NavDropdown.Item onClick={logout}>LogOut</NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
      </Container>
    </Navbar>
  );
}

const styles = StyleSheet.create({
  userProfile: {
    width: 48,
    height: 48,
  },
  role: {
    fontSize: '0.75rem',
  },
  topNavbar: {
    borderBottom: `1px solid ${Colors.Geyser}`,
  },
});

export default TopNavBar;
