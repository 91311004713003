import React, { useEffect, useRef, useState, useContext } from "react";

import {
  Button,
  Container,
  Dropdown,
  Row,
  Form,
  Ratio,
  Col,
  InputGroup,
} from "react-bootstrap";
import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import {
  faChevronLeft,
  faChevronRight,
  faPen,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { For } from "tsx-control-statements/components";
import Markdown from "markdown-to-jsx";

import GeneralRoutes from "constants/generalRoutes";
import UserTemplate from "typescript/userTemplate";
import Roles from "constants/roles";
import { useNavigate } from "react-router-dom";
import Templates from "constants/templates";
import useIncidentContext from "../lib/hooks/useIncidentContext";
import useTemplate from "hooks/api/useTemplate";
import TemplatePage1 from "components/templatePage1/templatePage1";
import TemplatePage2 from "components/templatePage2/templatePage2";

function SelectTemplate() {
  const navigate = useNavigate();
  const { incidentText, setGeneratedResult } = useIncidentContext();
  const { generate } = useTemplate();

  const [editBtnClicked, setEditBtnClicked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [userCase, setUserCase] = useState<string>(
    Templates[0].templateStructure
  );
  const [currentUserTemplate, setCurrentUserTemplate] = useState<UserTemplate>(
    Templates[0]
  );

  const EditBtnHandler = () => {
    setEditBtnClicked(!editBtnClicked);
  };

  const EditTextHandler = (userText: string) => {
    setUserCase(userText);
  };

  const TemplateSelectHandler = (selectedTemplate: UserTemplate) => {
    setCurrentUserTemplate(selectedTemplate);
    setUserCase(selectedTemplate.templateStructure);
  };

  const NextBtnHandler = async () => {
    if (loading) {
      navigate("/" + Roles.Officer + GeneralRoutes.LoadingPage);
    }
    const genTemplate = await generate({
      title: currentUserTemplate.value,
      incident: incidentText,
      templateStructure: currentUserTemplate.templateStructure,
    });
    setGeneratedResult(genTemplate);
    navigate("/" + Roles.Officer + GeneralRoutes.Results);
    setLoading(false);
  };

  const BackBtnHandler = () => {
    navigate("/" + Roles.Officer + GeneralRoutes.InsertCase);
  };

  let template: UserTemplate = {
    title: "",
    description: "",
    templateStructure: "",
    value: "",
  };
  return (
    <Container fluid className="px-4">
      <Row>
        <Col>
          <h1>Choose the Incident Type</h1>
        </Col>
        <Col className="d-flex justify-content-end my-auto">
          <InputGroup className={css(styles.incidentType)}>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                {currentUserTemplate.title}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <For each="template" of={Templates}>
                  <Dropdown.Item
                    key={template.title}
                    className={css(styles.textFont)}
                    eventKey={template.title}
                    onClick={() => TemplateSelectHandler(template)}
                  >
                    <span className="">{template.title}</span>
                  </Dropdown.Item>
                </For>
              </Dropdown.Menu>
            </Dropdown>
            <InputGroup.Text>{currentUserTemplate.description}</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col className="d-flex flex-row justify-content-between">
          <h3 className="fs-5">Preview</h3>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => {
              !editBtnClicked ? EditBtnHandler() : EditBtnHandler();
            }}
          >
            <FontAwesomeIcon
              icon={!editBtnClicked ? faPen : faSave}
              className="me-2"
            />
            <span>{!editBtnClicked ? "Edit" : "Done"}</span>
          </Button>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <div className={css(styles.textFont, styles.textArea)}>
            <div
              className={classNames(css(styles.TemplatePageWidth), "mb-5 pb-5")}
            >
              <TemplatePage1 />
            </div>
            <div className={classNames(css(styles.TemplatePageWidth))}>
              <TemplatePage2 />
            </div>
          </div>
          {/* <Choose>
            <When condition={editBtnClicked}>
              <Form.Control
                as="textarea"
                className={classNames(css(styles.textFont, styles.textArea))}
                color="white"
                onChange={(e) => EditTextHandler(e.target.value)}
                value={userCase}></Form.Control>
            </When>
            <Otherwise>
              <div className={classNames(css(styles.textFont, styles.textArea), 'p-2 rounded ')}>
                <Markdown options={{ wrapper: 'article' }}>{userCase}</Markdown>
              </div>
            </Otherwise>
          </Choose> */}
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <Button size="lg" variant="secondary" onClick={BackBtnHandler}>
            <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
            Back
          </Button>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button size="lg" onClick={NextBtnHandler}>
            Next
            <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  textFont: {
    fontSize: "0.95rem",
  },
  textArea: {
    border: "2px solid",
    height: "60vh",
    maxHeight: "60vh",
    overflowY: "auto",
  },
  incidentType: {
    width: "fit-content",
  },
  TemplatePageWidth: {
    width: "95%",
  },
});

export default SelectTemplate;
