const Templates = [
  {
    title: 'Child Abuse',
    description: 'Physical, Emotional, Sexual Abuse or Neglect, Violence',
    value: 'CHILD_ABUSE',
    templateStructure: `
    Can you please provide the following information in the given format:
    Context:
  Incident Type: **Child Abuse**
  1. Read the incident text and identify the following:
  - Identify all children, adults, and alleged perpetrator(s) in the household. Try to find each person's first name, last name, middle initial, aliases, sex, birth date/age, ethnicity, relationship to the victim, and whether the person is a perpetrator or a victim. Also, identify the basis of suspicions.
  2. Create a JSON object from the identified information. Fill in all fields using only the information mentioned in the incident text. If you don't know, put 'NA' in those fields. 
  3. For the fields that require a True/False answer, state True or False. Choose the given options as stated in $option1|option2|option3|...|optionN$. 
  4. Under 'reasonOfSuspicion', state the reasons for suspicion. Include details about each child’s injuries, abuse or maltreatment, any evidence of prior injuries, abuse or maltreatment to the child or his siblings, and any evidence or suspicions of parental behavior contributing to the problem. 
  In 'gotAbusedOrMaltreated', if 'other' is is marked as True, provide more information about it in the ‘otherDetails’ section.
  5. Follow this JSON format:
  {date: , time: , precinct:{serialNo: , precinctName: }, 
  complaint: {isComplaint: True/False, complaintNo: , complaintDate: , complaintTime: , complaintLocation: , complaintType: ,  precinctName: },
  arrest: {isArrest: True/False, arrestDate: , arrestTime: , arrestLocation: , arrestType: , arrestPrecinct: , arrestNo: },
  household:[
  {firstName: , lastName: ,middleInitial: , aliases: , sex: , birthDate: {year: ,month: ,day: }, isChild: True/False, ethnicity: $blackOrAfricanAmerican|alaskanNative|asian|nativeAmerican|nativeHawaiianPacificIslander|white|other|unknown$, relationshipToVictim: $parent|parentalSubstitute|fosterParent|grandparent|relative|sibling|child|noRelationship|unknown$, suspicions: $gotAbused|gotMaltreated|none|unknown$, perpetratorOrVictim: $perpetrator|victim|other$, streetAddress: , borough:, telephone: ,
  gotAbusedOrMaltreated: { doaFatality: True/False, childsDrugAlcoholUse: True/False, educationalNeglect: True/False, fractures: True/False, poisoningNoxiousSubstances: True/False, emotionalNeglect: True/False, internalInjuries: True/False, chokingTwistingShaking: True/False, inadequateFoodClothingShelter: True/False, lacerationsBruisesWelts: True/False, lackOfMedicalCare: True/False, lackOfSupervision: True/False, burnsScalding: True/False, malnutritionFailureToThrive: True/False, abandonment: True/False, swellingDislocationSprains: True/False, inadequateGuardianship: True/False, parentsDrugAlcoholMisuse: True/False, excessiveCorporalPunishment: True/False, sexualAbuse: True/False, other: True/False, otherDetails: }}],
  reasonOfSuspicion: , suspiciousDate:{year: ,month: ,day: }, suspiciousTime: , 
  actionsTakenOrAboutToBeTake: {medicalExam: True/False, Photographs: True/False, XRay: True/False, Hospitalization: True/False, removalKeeping: True/False, returnedHome: True/False, medicalExam: True/False, notifiedDA: True/False},
  complainantInformation: {name: , address: , borough: , telephone: , relationshipToVictim: },
  InfoReportingPoliceOfficer: {rank: , lastName: , command: , taxRegistrationNo: , date: , time: },
  }
  6. Output only the above mentioned values
  Please note that the JSON object should be in the same format as the example provided above.
    `,
  },
];

export default Templates;
