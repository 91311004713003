import React, { useState } from 'react';

import * as Yup from 'yup';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import AWSCognitoEmailVerification from 'components/auth/Registration/AWSCognitoEmailVerification';
import FaceBookIcon from 'assets/ServiceIcons/FaceBook.svg';
import GoogleIcon from 'assets/ServiceIcons/google.svg';
import TwitterIcon from 'assets/ServiceIcons/twitterX.svg';
import useAuth from 'hooks/useAuth';
import {SidebarItems} from "constants/sidebarItems";

const AuthLogin = () => {
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isUserConfirmed, setIsUserConfirmed] = useState(true);
  const navigate = useNavigate();

  if(!isUserConfirmed){
    return <AWSCognitoEmailVerification/>;
  }
  
  return (
    <div className={css(styles.formContainer)}>
      <Formik
        initialValues={{ email: '', password: '', submit: null }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: Yup.string().max(255).required('Password is required'),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            await login(values.email, values.password);
            setStatus({ success: true });
            navigate(SidebarItems[0].link);
          } catch (err) {
            setStatus({ success: false });
            // @ts-ignore
            setErrors({ submit: err });
            // @ts-ignore
            if (err === 'User is not confirmed') {
              alert('User Is Not Confirmed. Please verify your account by using the verification code sent to your email.');
              setIsUserConfirmed(false);
            }
          } finally {
            setLoading(false);
            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="email">
              <InputGroup className={css(styles.formElements)}>
                <Form.Control
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter email address"
                />
              </InputGroup>
              {touched.email && errors.email && (
                <Form.Text className="text-danger">{errors.email}</Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="password">
              <InputGroup className={css(styles.formElements)}>
                <Form.Control
                  type='password'
                  value={values.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter password"
                />
              </InputGroup>
              {touched.password && errors.password && (
                <Form.Text className="text-danger">{errors.password}</Form.Text>
              )}
              {errors.submit && (
                <div className="text-danger">
                  {errors.submit}
                </div>
              )}
              <Button variant="dark" disabled={isSubmitting || loading} type="submit"
                className={css(styles.formElements)}>
                {loading ? 'Loading...' : 'Login'}
              </Button>
            </Form.Group>

            <div className={css(styles.forgotPasswordText)}>
              <Button
                variant='link'
                href='/forgot-password'
              >
                Forgot Password ?
              </Button>
            </div>

            <div className={css(styles.createAccountText)}>
                Don't Have An Account? Create A New Account 
              <Button
                variant='link'
                href='/register'
              >
                   here
              </Button>
            </div>
            <div style={{marginTop: '30px'}}>
              <Button className={css(styles.serviceElements, styles.facebookButton)}>
                <img src={FaceBookIcon} alt="Facebook" className={css(styles.iconFit)}/> Continue With Facebook
              </Button>
              <Button variant="dark" className={css(styles.serviceElements)}>
                <img src={TwitterIcon} alt="Twitter" className={css(styles.iconFit)}/> Continue With Twitter
              </Button>
              <Button className={css(styles.serviceElements, styles.whiteButton)}>
                <img src={GoogleIcon} alt="Google" className={css(styles.iconFit)}/> Continue With Google
              </Button>
            </div>

          </Form>
        )}
      </Formik>
    </div>
  );
};

const styles = StyleSheet.create({
  formContainer: {
    height: '100%',
    width: '70%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#c7d4da',
    padding: '10px',
  },
  formElements: {
    marginTop: '20px',
    width: '100%',
    height: '70px',
    fontSize: '1.2rem',
    border: '2px solid black',
  },
  createAccountText: {
    marginTop: '10px',
    marginBottom: '60px',
    width: '100%',
    textAlign: 'center',
    fontSize: '1rem',
    color: '#000',
  },
  serviceElements: {
    marginTop: '15px',
    width: '100%',
    height: '60px',
    fontSize: '1.2rem',
    borderRadius: '20px',
    border: 'none',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  createAccountLink: {
    color: 'blue',
    textDecoration: 'underline',
    marginLeft: '4px',
  },
  forgotPasswordText:{
    color: 'blue',
    textDecoration: 'underline',
  },
  curvyButton: {
    borderRadius: '20px',
  },
  whiteButton: {
    backgroundColor: 'white',
    color:'black'
  },
  facebookButton: {
    backgroundColor: 'blue',
  },
  iconFit: {
    maxWidth: '20%',
    maxHeight: '100%',
  },
});

export default AuthLogin;
