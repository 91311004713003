import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Swal from 'sweetalert2';
import { useLocation, useNavigate } from "react-router-dom";

import { ACCESS_TOKEN, ID_TOKEN, CURRENT_USER } from 'constants/cookies';
import { AUTH } from 'constants/api';
import { AuthenticationRoutes } from 'constants/generalRoutes';
import AuthLogin from 'components/auth/Login/LoginPage';
import AuthRegister from 'components/auth/Registration/RegistrationPage';
import CodeVerification from 'components/auth/Registration/AWSCognitoEmailVerification';
import ForgotPassword from 'components/auth/ResetPassword/ResetPasswordPage';
import useCookie from 'hooks/useCookie'
import useAxiosPost from "services/middleware/useAxiosPost";

const setSession = (accessToken: string | null, idToken: string | null) => {
    const { setCookie, removeCookies } = useCookie()
    if (accessToken && idToken) {
        setCookie(ACCESS_TOKEN, accessToken);
        setCookie(ID_TOKEN, idToken);
    } else {
        removeCookies();
    }
};

const AWSCognitoContext = createContext<any>(null);
const LOGIN_PATHS = [
    AuthenticationRoutes.Login,
    AuthenticationRoutes.Register,
    AuthenticationRoutes.ForgotPassword,
    AuthenticationRoutes.CodeVerification,
];


// @ts-ignore
export const AWSCognitoProvider = ({ children }) => {
    const [authUser, setAuthUser] = useState();
    const { getCookie, removeCookies, setCookie } = useCookie()
    const location = useLocation();
    const navigate = useNavigate();
    const currentPath = location.pathname as AuthenticationRoutes;
    const { post: postLogin, loading: loginLoading, error } = useAxiosPost(AUTH.LOGIN);
    const {
        post: postValidateToken,
        error: validateTokenError,
    } = useAxiosPost(AUTH.VALIDATE_TOKEN);

    const {
        post: postConfirmEmail,
        error: confirmEmailError,
    } = useAxiosPost(AUTH.CONFIRM_EMAIL);

    const {
        post: postReSendVerificationCode,
        error: reSendVerificationCodeError,
    } = useAxiosPost(AUTH.RESEND_VERIFICATION_CODE);

    const {
        post: postSignUp,
        error: signUprError,
    } = useAxiosPost(AUTH.SIGNUP);

    const {
        post: postInitiatePasswordReset,
        error: InitiatePasswordError,
    } = useAxiosPost(AUTH.INITIATE_PASSWORD_RESET);

    const {
        post: postConfirmPasswordReset,
        error: ConfirmPasswordError,
    } = useAxiosPost(AUTH.CONFIRM_PASSWORD_RESET);

    if (error || signUprError || confirmEmailError || reSendVerificationCodeError || signUprError) {
        const errorMessage: any = error || signUprError || confirmEmailError || reSendVerificationCodeError || signUprError;
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: errorMessage,
          });
    }

    // @ts-ignore
    const navigateUser = () => {
        if (!LOGIN_PATHS.includes(currentPath) && currentPath !== AuthenticationRoutes.Login) {
            navigate(AuthenticationRoutes.Login,);
        }
    };

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = getCookie(ACCESS_TOKEN);
                const idToken = getCookie(ID_TOKEN);
                if (serviceToken && idToken) {
                    const isValid = await validateToken(serviceToken);
                    if (!isValid) {
                        navigateUser();
                    }
                } else {
                    navigateUser();
                }
            } catch (err) {
                console.error(err);
            }
        };
        init();
    }, [location.pathname]);

    const register = async (
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        gender: string,
        phoneNumber: string,
        role: string,
        cop_id: string
    ) => {
        const postBody = {
            email: email,
            password: password,
            role: role,
            cop_id: cop_id,
            firstName: firstName,
            lastName: lastName,
            gender: gender,
            phoneNumber: phoneNumber,
        };
        const response: any = await postSignUp(postBody);
        return response;
    };

    const codeVerification = async (email: string, code: string) => {
        const postBody = {
            email: email,
            code: code
        };
        const response: any = await postConfirmEmail(postBody);
        return response;
    };


    const reSendVerificationCode = async (email: string) => {
        const postBody = { email: email };

        const response: any = await postReSendVerificationCode(postBody);
        return response;
    };

    const login = async (email: string, password: string) => {
        const postBody = {
            email: email,
            password: password,
        };

        const response: any = await postLogin(postBody);
        if (response) {
            setSession(response.AccessToken, response.IdToken);
            setCookie(CURRENT_USER, JSON.stringify(response.user));
            setAuthUser(response.user);
            navigate("/")
        }
    };

    const validateToken = async (accessToken: string) => {
        const postBody = { accessToken: accessToken };

        const response: any = await postValidateToken(postBody);
        return response;
    }

    // const refreshTokens = async (refreshToken: string): Promise<any | null> => {
    //     const tokenEndpoint = process.env.TOKEN_END_POINT!;
    //
    //     const formData = new URLSearchParams();
    //     formData.append('grant_type', 'refresh_token');
    //     formData.append('client_id', process.env.REACT_APP_AWS_APP_CLIENT_ID!);
    //     formData.append('refresh_token', refreshToken);
    //
    //     const headers = {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     };
    //
    //     try {
    //         const response = await httpClient.post(tokenEndpoint, formData, {
    //             headers
    //         });
    //
    //         if (response.status === 200) {
    //             const data = response.data;
    //             return {
    //                 access_token: data.access_token,
    //                 id_token: data.id_token,
    //                 refresh_token: data.refresh_token
    //             };
    //         } else {
    //             throw new Error('Token refresh request failed');
    //         }
    //     } catch (error) {
    //         console.error('Token refresh error:', error);
    //         return null;
    //     }
    // };


    const forgotPassword = async (email: string) => {
        const postBody = { email: email };

        const response: any = await postInitiatePasswordReset(postBody);
        return response;
    }

    const resetPassword = async (email: string, code: string, new_password: string) => {
        const postBody = {
            email: email,
            code: code,
            new_password: new_password,
        };

        const response: any = await postConfirmPasswordReset(postBody);
        return response;
    }

    const logout = () => {
        removeCookies();
        navigateUser();
    }

    return (
        <AWSCognitoContext.Provider value={{
            register,
            codeVerification,
            reSendVerificationCode,
            login,
            forgotPassword,
            resetPassword,
            logout
            }}
        >
            <Choose>
                <When condition={!getCookie(ACCESS_TOKEN)}>
                    <Choose>
                        <When condition={location.pathname === AuthenticationRoutes.Register}>
                            <AuthRegister />
                        </When>
                        <When condition={location.pathname === AuthenticationRoutes.CodeVerification}>
                            <CodeVerification />
                        </When>
                        <When condition={location.pathname === AuthenticationRoutes.ForgotPassword}>
                            <ForgotPassword />
                        </When>
                        <Otherwise>
                            <AuthLogin />
                        </Otherwise>
                    </Choose>
                </When>
                <Otherwise>
                    {React.Children.toArray(children)}
                </Otherwise>
            </Choose>
        </AWSCognitoContext.Provider>
    );
};

AWSCognitoProvider.propTypes = {
  children: PropTypes.node
};

export default AWSCognitoContext;
