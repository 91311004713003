import React, { useContext } from 'react';

import Col from 'react-bootstrap/Col';
import { css, StyleSheet } from 'aphrodite';
import Row from 'react-bootstrap/Row';

import LOGO from 'assets/images/police_Logo.jpg';
import BasicSuspicions from 'components/templatePage1/basicSuspicions';
import classNames from 'classnames';
import ComplainmentDetails from 'components/templatePage1/complainmentDetails';
import ComplainantInformation from 'components/templatePage1/complainantInformation';
import HeaderFillingDetails from 'components/templatePage1/headerFillingDetails';
import FirstSection from 'components/templatePage1/firstSection';
import PersonDetails from 'components/templatePage1/personDetails';
import ReportingOfficer from 'components/templatePage1/ReportingOfficer';
import StateReason from 'components/templatePage1/stateReson';
import useIncidentContext from '../../lib/hooks/useIncidentContext';

function TemplatePage1() {
  const { incidentKeyValueMap } = useIncidentContext();
  return (
    <div className={classNames(css(styles.a4Height), 'w-100')}>
      <div className="w-100 d-flex flex-column justify-content-center align-items-center text-dark">
        <h4 className={classNames(css(styles.mainTitleFont), 'd-flex')}>Four (4) completed copies of this form are required. </h4>
        <h4 className={classNames(css(styles.mainTitleFont), 'd-flex')}> See the bottom of this form for distribution instructions.</h4>
      </div>
      <Row className="p-0 border-4 border-bottom border-dark text-dark">
        <Col>
          <div className="d-flex flex-row">
            <div className={classNames(css(styles.logo), 'col-3')}>
              <img src={LOGO} alt="Avatar" className="w-100 h-auto" />
            </div>
            <div className={classNames(css(styles.subTitleFont), 'm-0 p-0 col-6 d-flex flex-column')}>
              REPORT OF SUSPECTED CHILD ABUSE OR MALTREATMENT <span className={classNames(css(styles.normalFont))}>PD 377-154 (Rev. 09-19) (DSS 2221-A)LE</span>
            </div>
            <div className="col-3">
              <div className="h-auto w-100 mt-2 pt-1 pb-1 d-flex justify-content-center align-items-center border"> Page 1 of 2</div>
            </div>
          </div>
          <div className="d-flex flex-row">
            <div className="col-5">STATE OF NEW YORK</div>
            <div className="col-7">DEPARTMENT OF SOCIAL SERVICE</div>
          </div>
        </Col>
        <HeaderFillingDetails />
      </Row>
      <div className="w-100 d-flex flex-column justify-content-center border-bottom">
        <FirstSection />
      </div>
      <Row>
        <PersonDetails />
      </Row>
      <Row className="p-2 text-dark">
        <ComplainmentDetails />
      </Row>
      <Row className="p-0 border-4 border-bottom border-dark text-dark">
        <BasicSuspicions />
      </Row>
      <Row className="tet-dark">
        <StateReason />
      </Row>
      <div className="text-dark">
        <ComplainantInformation />
      </div>
      <div className="p-0 border-4 border-bottom border-dark text-dark">
        <ReportingOfficer />
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  mainTitleFont: {
    fontSize: '1.3rem',
  },
  subTitleFont: {
    fontSize: '1rem',
  },
  normalFont: {
    fontSize: '0.8rem',
  },
  a4Height: {
    height: '1400px',
  },
  logo: {
    width: '100px',
  },
});
export default TemplatePage1;
