import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';

import { AuthenticationRoutes } from 'constants/generalRoutes';
import ErrorBoundaryMessage from 'components/layouts/ErrorBoundaryMessage';
import Roles from 'constants/roles';
import AuthLogin from 'components/auth/Login/LoginPage';
import AuthRegister from 'components/auth/Registration/RegistrationPage';
import CodeVerification from 'components/auth/Registration/AWSCognitoEmailVerification';
import ForgotPassword from 'components/auth/ResetPassword/ResetPasswordPage';
import PageLayout from 'components/layouts/PageLayout';
import UserProfile from 'components/UserProfile/UserProfile';

function NYPD() {
  return (
    <ErrorBoundary fallback={<ErrorBoundaryMessage />}>
      <Routes>
        <Route path={AuthenticationRoutes.Login} element={<AuthLogin />} />
        <Route path={AuthenticationRoutes.Register} element={<AuthRegister />} />
        <Route path={AuthenticationRoutes.CodeVerification} element={<CodeVerification />} />
        <Route path={AuthenticationRoutes.ForgotPassword} element={<ForgotPassword />} />
        <Route path={'/' + Roles.Officer + '/*'} element={<PageLayout />} />
        <Route path={'/' + Roles.User + '/*'} element={<UserProfile />} />
      </Routes>
    </ErrorBoundary>
  );
}

export default NYPD;
