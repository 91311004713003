import React, { useState } from 'react';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

interface Props {
  inputFieldText?: string;
  userInputHandler?: any;
  index1?: number;
  index2?: number;
  defaultValue?: any;
}

function RowTypeInput(prop: Props) {
  const EditTextHandler = (userText: any) => {
    prop.userInputHandler(prop.index1, prop.index2, userText);
  };
  return (
    <InputGroup size="sm" className={classNames(css(styles.inputBoxColur, styles.normalFont), 'd-flex flex-row h-100 p-0 border-0 rounded-0 w-100 p-0')}>
      <Form.Control
        className={classNames(css(styles.inputBoxWidth), 'h-100 border-0 p-0 m-0 bg-transparent rounded-0 border-bottom border-1 border-dark')}
        onChange={(e) => EditTextHandler(e.target.value)}
        value={prop.defaultValue}
      />
      <InputGroup.Text className={classNames(css(styles.textBoxWidth), 'text-dark w-auto rounded-0 h-100 p-0 m-0 border-0')}>{prop.inputFieldText}</InputGroup.Text>
    </InputGroup>
  );
}

const styles = StyleSheet.create({
  inputBoxColur: {
    background: '#C7E4FF',
  },
  inputBoxWidth: {
    maxWidth: '20%',
  },
  textBoxWidth: {
    maxWidth: '80%',
    width: '80%',
  },
  normalFont: {
    fontSize: '0.9rem',
  },
});
export default RowTypeInput;
