import React, { useState, ChangeEvent } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import useCurrentUser from 'hooks/useCurrentUser';
import UserProfileImage from "components/UserProfile/UserProfileImage";
import { If } from 'tsx-control-statements/components';

interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  role: string;
  copId: string;
  subId: string;
  phoneNumber: string;
}

function UserAccount() {
  const currentUser = useCurrentUser();
  const [userData, setUserData] = useState<UserData>({
    firstName: currentUser?.firstName || '',
    lastName: currentUser?.lastName || '',
    email: currentUser?.email || '',
    gender: currentUser?.gender || '',
    role: currentUser?.role || '',
    copId: currentUser?.copId || '',
    subId: currentUser?.subId || '',
    phoneNumber: currentUser?.phoneNumber || '',
  });
  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value
    });
  };

  const handlePhoneNumberEdit = () => {
    setIsEditingPhoneNumber(!isEditingPhoneNumber);
  };

  const handleSavePhoneNumber = () => {
    setUserData({
      ...userData,
      phoneNumber: userData.phoneNumber
    });
    setIsEditingPhoneNumber(false);
  };

  return (
      <div className="container">
        <h1 className="mt-5">User Account</h1>
        <Row>
          <Col md={7}>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>First Name:</Form.Label>
                <Form.Control type="text" name="firstName" value={userData.firstName} disabled />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Last Name:</Form.Label>
                <Form.Control type="text" name="lastName" value={userData.lastName} disabled />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email:</Form.Label>
                <Form.Control type="email" name="email" value={userData.email} disabled />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Gender:</Form.Label>
                <Form.Control type="text" name="gender" value={userData.gender} disabled />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Role:</Form.Label>
                <Form.Control type="text" name="role" value={userData.role} disabled />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Cop ID:</Form.Label>
                <Form.Control type="text" name="copId" value={userData.copId} disabled />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number:</Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control type="tel" name="phoneNumber" value={userData.phoneNumber} onChange={handleInputChange} disabled={!isEditingPhoneNumber} />
                  <If condition={!isEditingPhoneNumber}>
                    <Button variant="link" onClick={handlePhoneNumberEdit}>
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </Button>
                  </If>
                  <If condition={isEditingPhoneNumber}>
                    <Button variant="link" onClick={handleSavePhoneNumber}>
                      <FontAwesomeIcon icon={faSave} />
                    </Button>
                  </If>
                </div>
              </Form.Group>
            </Form>
          </Col>
          <Col md={5} className="d-flex align-items-center justify-content-center">
            <div className="profile-picture">
              <UserProfileImage />
            </div>
          </Col>
        </Row>
      </div>
  );
}

export default UserAccount;
