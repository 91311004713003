import React from 'react';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import LOGO from 'assets/images/transparent-horizontal-logo.svg';
import { copyRights, SidebarItems } from "constants/sidebarItems";
import {BsClipboard2Plus} from "react-icons/bs";
import {RiFileHistoryLine} from "react-icons/ri";
import {CgTemplate} from "react-icons/cg";
import {For} from "tsx-control-statements/components";

const UserSidebar: React.FC = () => {

  let template ={
    icon: '',
    menu: '',
    link: ''
  }

  const getIconComponent = (iconName: string) => {
    switch (iconName) {
      case 'BsClipboard2Plus':
        return <BsClipboard2Plus className="me-2" size={30} />;
      case 'RiFileHistoryLine':
        return <RiFileHistoryLine className="me-2" size={30} />;
      case 'CgTemplate':
        return <CgTemplate className="me-2" size={30} />;
      default:
        return null;
    }
  };

  return (
      <div
          className={classNames(
              css(styles.sidebar),
              'p-3 d-flex flex-column flex-shrink-0 text-white bg-dark'
          )}
      >
        <a href={SidebarItems[0].link} className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
          <img src={LOGO} alt="Logo" />
        </a>
        <hr />
        <Nav className="nav nav-pills mb-auto flex-column">
          <For each = "template" of={SidebarItems}>
            <NavLink href={template.link} className="text-white">
              {getIconComponent(template.icon)}
              {template.menu}
            </NavLink>
          </For>
        </Nav>
        <hr />
        <div className={classNames(css(styles.copyRights))}>{copyRights}</div>
      </div>
  );
};

const styles = StyleSheet.create({
  sidebar: {
    width: '20vw',
    maxWidth: '20vw',
  },
  copyRights: {
    fontSize: '0.75rem',
  },
});

export default UserSidebar;
