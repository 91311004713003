import React from "react";

import classNames from "classnames";
import { css, StyleSheet } from "aphrodite";
import { Route, Routes } from "react-router-dom";

import GeneralRoutes from "constants/generalRoutes";
import InsertCase from "components/InsertCase";
import LoadingPage from "components/LoadingPage";
import ResultDisplay from "components/GeneratedResult";
import SelectTemplate from "components/SelectTemplate";
import Sidebar from "../sidebarComponents/Sidebar";
import ViewCase from "components/viewComponents/ViewCase";
import IncidentContextProvider from "../../lib/contexts/IncidentContextProvider";
import TopNavBar from "./TopNavBar";

function PageLayout() {
  return (
    <div
      className={classNames(css(styles.wrapper), "d-flex flex-row bg-light")}
    >
      <Sidebar />
      <div className="d-flex flex-column position-relative">
        <TopNavBar />
        <div
          className={classNames(
            css(styles.selectTemplateWrapper),
            "d-flex justify-content-center mt-4"
          )}
        >
          <IncidentContextProvider>
            <Routes>
              <Route path={GeneralRoutes.InsertCase} element={<InsertCase />} />
              <Route
                path={GeneralRoutes.SelectTemplate}
                element={<SelectTemplate />}
              />
              <Route path={GeneralRoutes.Results} element={<ResultDisplay />} />
              <Route path={GeneralRoutes.ViewCase} element={<ViewCase />} />
              <Route
                path={GeneralRoutes.LoadingPage}
                element={<LoadingPage />}
              />
            </Routes>
          </IncidentContextProvider>
        </div>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  wrapper: {
    minHeight: "100vh",
    height: "100vh",
  },
  sidebarWrapper: {
    width: "20%",
  },
  selectTemplateWrapper: {
    width: "80vw",
    height: "80vh"
  },
});

export default PageLayout;
