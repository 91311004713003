import React from 'react';

function UserSecurity(){
  return (
    <div>
      <h1>User Security</h1>
    </div>
  );
}

export default UserSecurity;
