import GeneralRoutes from "constants/generalRoutes";
import Roles from "constants/roles";

export interface SidebarItem {
  icon: string;
  menu: string;
  link: string;
}

const officerInsert = "/" + Roles.Officer + GeneralRoutes.InsertCase;
const officerView = "/" + Roles.Officer + GeneralRoutes.ViewCase;
const officerTemplate = "/" + Roles.Officer + GeneralRoutes.SelectTemplate;

export const SidebarItems: SidebarItem[] = [
    {
      icon: 'BsClipboard2Plus',
      menu: 'New',
      link: officerInsert,
    },
    {
      icon: 'RiFileHistoryLine',
      menu: 'History',
      link: officerView,
    },
    {
      icon: 'CgTemplate',
      menu: 'Template',
      link: officerTemplate,
    },
];

export const copyRights = `© ${new Date().getFullYear()}. COPNOTE All rights reserved`;