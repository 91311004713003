import React, { useState } from 'react';

import * as Yup from 'yup';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { useFormik } from 'formik';

import { AuthenticationRoutes } from 'constants/generalRoutes';
import AWSCognitoEmailVerification from 'components/auth/Registration/AWSCognitoEmailVerification';
import FaceBookIcon from 'assets/ServiceIcons/FaceBook.svg';
import { genderOptions, roleOptions } from 'components/auth/Registration/FormOptions';
import GoogleIcon from 'assets/ServiceIcons/google.svg'
import { REGISTERED } from 'constants/cookies';
import TwitterIcon from 'assets/ServiceIcons/twitterX.svg';
import useAuth from "hooks/useAuth";
import useCookie from 'hooks/useCookie'

const AuthRegister = () => {
  const { setCookie, getCookie } = useCookie()

  const { register } = useAuth();
  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(getCookie(REGISTERED) == 'true' || false);


  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      gender: '',
      password: '',
      confirmPassword: '',
      contactNumber: '',
      role: '',
      cop_id: '',
      signup_response:''
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required('First Name is required'),
      lastname: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      gender: Yup.string().required('Gender is required'),
      password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm Password is required'),
      contactNumber: Yup.string(),
      role: Yup.string().required('Role is required'),
      cop_id: Yup.string().required('Cop ID is required')
    }),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        setLoading(true);
        await register(
          values.email,
          values.password,
          values.firstname,
          values.lastname,
          values.gender,
          values.contactNumber,
          values.role,
          values.cop_id
        );
        setRegistered(true);
        setCookie(REGISTERED, 'true');

      } catch (err) {
        // @ts-ignore
        formik.setErrors({ signup_response: err });
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      {registered ? (
        <AWSCognitoEmailVerification />
      ) : (
        <Form noValidate onSubmit={formik.handleSubmit} className={css(styles.form)}>
          <Row>
            <Col>
              <Form.Group controlId="firstname">
                <Form.Control type="text" placeholder="First Name" {...formik.getFieldProps('firstname')}
                  className={css(styles.formElements)}/>
                {formik.touched.firstname && formik.errors.firstname && <div className={css(styles.yupError)}>{formik.errors.firstname}</div>}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="lastname">
                <Form.Control type="text" placeholder="Last Name" {...formik.getFieldProps('lastname')}
                  className={css(styles.formElements)}/>
                {formik.touched.lastname && formik.errors.lastname && <div className={css(styles.yupError)}>{formik.errors.lastname}</div>}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="gender">
            <Form.Control as="select" {...formik.getFieldProps('gender')} className={css(styles.formElements)}>
              <option value="">Select Gender</option>
              {genderOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
            {formik.touched.gender && formik.errors.gender && <div className={css(styles.yupError)}>{formik.errors.gender}</div>}
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Control type="email" placeholder="Email" {...formik.getFieldProps('email')}
              className={css(styles.formElements)}/>
            {formik.touched.email && formik.errors.email && <div className={css(styles.yupError)}>{formik.errors.email}</div>}
          </Form.Group>
          <Form.Group controlId="contactNumber">
            <Form.Control type="tel" placeholder="Phone Number" {...formik.getFieldProps('contactNumber')}
              className={css(styles.formElements)}/>
            {formik.touched.contactNumber && formik.errors.contactNumber && <div className={css(styles.yupError)}>{formik.errors.contactNumber}</div>}
          </Form.Group>
          <Form.Group controlId="role">
            <Form.Control as="select" {...formik.getFieldProps('role')} className={css(styles.formElements)}>
              <option value="">Select Role</option>
              {roleOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
            {formik.touched.role && formik.errors.role && <div className={css(styles.yupError)}>{formik.errors.role}</div>}
          </Form.Group>
          <Form.Group controlId="cop_id">
            <Form.Control type="text" placeholder="Cop ID" {...formik.getFieldProps('cop_id')}
              className={css(styles.formElements)}/>
            {formik.touched.cop_id && formik.errors.cop_id && <div className={css(styles.yupError)}>{formik.errors.cop_id}</div>}
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Control type="password" placeholder="Password" {...formik.getFieldProps('password')}
              className={css(styles.formElements)}/>
            {formik.touched.password && formik.errors.password && <div className={css(styles.yupError)}>{formik.errors.password}</div>}
          </Form.Group>
          <Form.Group controlId="confirmPassword">
            <Form.Control type="password" placeholder="Confirm Password" {...formik.getFieldProps('confirmPassword')}
              className={css(styles.formElements)}/>
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className={css(styles.yupError)}>{formik.errors.confirmPassword}</div>
            )}
          </Form.Group>
          <div className={css(styles.createAccountText)}>
            <Button type="submit" disabled={formik.isSubmitting} className={css(styles.formElements)}>
              {loading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                  {' '}Loading...
                </>
              ) : (
                'REGISTER'
              )}
            </Button>
          </div>
          {formik.errors.signup_response && <div>{formik.errors.signup_response}</div>}
          <div className={css(styles.loginAccountText)}>
              <span>Already Have An Account? SignIn</span>
              <Button
                  className='px-1 pb-2'
                  variant='link'
                  href={AuthenticationRoutes.Login}
                >
                   here
                </Button>
          </div>
          <div style={{marginTop: '30px'}}>
            <Button className={css(styles.serviceElements, styles.facebookButton)}>
              <img src={FaceBookIcon} alt="Facebook" className={css(styles.iconFit)}/> Register Using Facebook
            </Button>
            <Button variant="dark" className={css(styles.serviceElements)}>
              <img src={TwitterIcon} alt="Twitter" className={css(styles.iconFit)}/> Register Using Twitter
            </Button>
            <Button className={css(styles.serviceElements, styles.whiteButton)}>
              <img src={GoogleIcon} alt="Google" className={css(styles.iconFit)}/> Register Using Google
            </Button>
          </div>
        </Form>
      )}
    </Container>
  );
};

const styles = StyleSheet.create({
  formElements: {
    marginTop: '20px',
    width: '100%',
    height: '40px',
    fontSize: '1.2rem',
    border: '1px solid black',
  },
  serviceElements: {
    marginTop: '15px',
    width: '100%',
    height: '60px',
    fontSize: '1.2rem',
    borderRadius: '20px',
    border: 'none',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: '65%',
  },
  whiteButton: {
    backgroundColor: 'white',
    color: 'black',
  },
  createAccountText: {},
  loginAccountText: {
    marginTop: '30px'
  },
  facebookButton: {
    backgroundColor: 'blue',
  },
  iconFit: {
    maxWidth: '20%',
    maxHeight: '100%',
  },
  yupError: {
    color: 'red',
  },
});

export default AuthRegister;
