/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react';
import speechToTextUtils from './utilityTranscriber';

const SpeechToText = () => {
  const [transcribedData, setTranscribedData] = useState<string[]>([]);
  const [interimTranscribedData, setInterimTranscribedData] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en-US');
  const [sid, setSid] = useState('002');

  function flushInterimData() {
    if (interimTranscribedData !== '') {
      setInterimTranscribedData('');
      setTranscribedData((oldData) => [...oldData, interimTranscribedData]);
    }
  }

  function handleDataReceived(data: any, isFinal: any) {
    if (isFinal) {
      setInterimTranscribedData('');
      setTranscribedData((oldData) => [...oldData, data]);
    } else {
      setInterimTranscribedData(data);
    }
  }

  function getTranscriptionConfig() {
    return {
      audio: {
        encoding: 'LINEAR16',
        sampleRateHertz: 16000,
        languageCode: selectedLanguage,
      },
      interimResults: true,
    };
  }

  function onStart() {
    setTranscribedData([]);
    setIsRecording(true);

    speechToTextUtils.initRecording(
      getTranscriptionConfig(),
      handleDataReceived,
      (error: any) => {
        console.error('Error when transcribing', error);
        setIsRecording(false);
      },
    );
  }

  function onStop() {
    setIsRecording(false);
    flushInterimData();
    speechToTextUtils.stopRecording();
  }

  return {onStart, onStop, transcribedData, interimTranscribedData}
};

export default SpeechToText;
