import React from 'react';

import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import UserSidebar from 'components/sidebarComponents/UserSidebar';
import TopNavBar from 'components/layouts/TopNavBar';
import { Route, Routes } from 'react-router-dom';
import UserAccount from 'components/UserProfile/UserAccount';
import UserSecurity from 'components/UserProfile/UserSecurity';
import { UserProfileRoutes } from 'constants/generalRoutes';

function UserProfile() {
  return (
    <div
      className={classNames(css(styles.wrapper), 'd-flex flex-row bg-light')}
    >
      <UserSidebar/>
      <div className="d-flex flex-column position-relative">
        <TopNavBar/>
        <div
          className={classNames(
            css(styles.selectTemplateWrapper),
            'mt-4 d-flex justify-content-center'
          )}
        >
          <Routes>
            <Route path={UserProfileRoutes.UserAccount} element={<UserAccount />} />
            <Route path={UserProfileRoutes.UserSecurity} element={<UserSecurity />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    minHeight: '100vh',
    height: '100vh',
  },
  sidebarWrapper: {
    width: '20%',
  },
  selectTemplateWrapper: {
    width: '80vw',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 64px - 4rem)'
  }
});

export default UserProfile;
