import React, { useEffect, useRef, useState } from 'react';

import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { usePDF, Margin } from 'react-to-pdf';

import { EditButtonState } from 'constants/enums';
import { faArrowLeft, faFile, faEdit, faRedo, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GeneralRoutes from 'constants/generalRoutes';
import Roles from 'constants/roles';
import { useNavigate } from 'react-router-dom';
import useIncidentContext from '../lib/hooks/useIncidentContext';
import Markdown from 'markdown-to-jsx';
import classNames from 'classnames';
import { StyleSheet, css } from 'aphrodite';
import TemplatePage1 from 'components/templatePage1/templatePage1';
import TemplatePage2 from 'components/templatePage2/templatePage2';

interface Props {
  data?: string[];
}

function ResultDisplay({ data }: Props) {
  const { toPDF, targetRef } = usePDF({
    method: 'save',
    filename: 'case.pdf',
    page: { margin: Margin.MEDIUM },
  });
  const secondPageRef = React.useRef(null);
  const [isContentReady, setIsContentReady] = useState(false);

  const navigate = useNavigate();
  const { generatedResult, setGeneratedResult } = useIncidentContext();

  const [isTextAreaEditable, setIsTextAreaEditable] = useState(false);
  const [editButtonCaption, setEditButtonCaption] = useState(EditButtonState.Edit);
  const [editedResult, setEditedResult] = useState('');

  const handleTryAgain = () => {};

  const handleEdit = () => {
    setIsTextAreaEditable(!isTextAreaEditable);
    setEditButtonCaption(isTextAreaEditable ? EditButtonState.Edit : EditButtonState.Done);
  };

  const handleBackBtn = () => {
    navigate('/' + Roles.Officer + GeneralRoutes.SelectTemplate);
  };

  const handleSave = () => {};

  return (
    <Container fluid className="px-4">
      <Row>
        <Col>
          <h1>Check out the Filled Form</h1>
        </Col>
        <Col className="d-flex justify-content-end my-auto">
          <Button size="sm" variant="outline-primary" onClick={handleTryAgain} className="me-2">
            <FontAwesomeIcon icon={faRedo} className="me-2" />
            Try Again
          </Button>
          <Button size="sm" className="me-2" variant="outline-primary" onClick={() => toPDF()}>
            <FontAwesomeIcon icon={faFile} className="me-2" />
            Download
          </Button>
          <Button size="sm" variant="outline-primary" onClick={handleEdit}>
            <FontAwesomeIcon icon={isTextAreaEditable ? faSave : faEdit} className="me-2" />
            {editButtonCaption}
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <div className={css(styles.textFont, styles.textArea)}>
            <div ref={targetRef}>
              <div className={classNames(css(styles.TemplatePageWidth), 'mb-5 pt-5 pb-5 d-flex justify-content-center align-items-center')}>
                <TemplatePage1 key={0} />
              </div>
              <div className={classNames(css(styles.TemplatePageWidth), 'mt-5 pt-5 d-flex justify-content-center align-items-center')}>
                <TemplatePage2 key={1} />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <Button size="lg" variant="outline-primary" onClick={handleBackBtn}>
            <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
            Back
          </Button>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button size="lg" onClick={handleSave}>
            Save
            <FontAwesomeIcon icon={faSave} className="ms-2" />
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  textArea: {
    border: '2px solid',
    height: '60vh',
    maxHeight: '70vh',
    overflowY: 'auto',
  },
  TemplatePageWidth: {
    width: '95%',
  },
  textFont: {
    fontSize: '0.95rem',
  },
});

export default ResultDisplay;
